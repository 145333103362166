import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const ProfitRoomLink = ({ colour, label, profitRoomId, type }) => {
  const openBookingSite = evt => {
    evt.preventDefault()
    Booking.Open({ OfferID: profitRoomId })
  }


  let classes

  if (type === 'Button') {
    switch (colour) {
      case 'blue':
        classes =
          'button px-6 py-3 text-center button-full relative inline-flex rounded-4 text-sm leading-none font-bold border border-solid border-blue-500 hover:bg-blue-100 text-blue-500'
        break
      case 'green':
        classes =
          'button px-6 py-3 text-center relative inline-flex rounded-4 text-sm leading-none font-bold border border-aqua-500 bg-aqua-500 hover:bg-opacity-80 text-white'
        break
      default:
        // likely text
        classes =
          'button px-6 py-3 text-center relative inline-flex rounded-4 text-sm leading-none font-bold border border-aqua-500 bg-aqua-500 hover:bg-opacity-80 text-white'
        break
    }
  } else {
    classes = 'button text-sm leading-none font-bold text-blue-500'
  }

  return (
    <button type="button" className={classes} onClick={openBookingSite}>
      {label}
    </button>
  )
}

export default ProfitRoomLink

ProfitRoomLink.propTypes = {}
